//abstracts
@import '../abstracts/variables';
@import '../abstracts/mixins';
@import '../abstracts/functions';

//vendors
@import '~bootstrap/dist/css/bootstrap.css';
@import '../vendors/fancybox';

//partials
@import '../base/fonts';
@import '../base/global';
@import '../components/language-selector';
@import '../components/tarteaucitron';
@import '../elements/header';
@import '../elements/header-cart';
@import '../elements/menu';
@import '../widgets/rslink';
@import '../widgets/contact';
@import '../elements/footer';

// jQuery-ui
@import '~jquery-ui/themes/base/all.css';

// custom styles
//@import 'customfront'

body{    
    color: $colorText;
    background: $body-bg url(/img/bg.png) repeat center center;
    background-size: cover;
}

#content{
    background: url(/img/phoenix-bg.png) no-repeat left center;
    background-size: initial;
}
