@import '../modules/recrutement/_recrutement-annonce';

.recrutement {
    &-annonce {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &-title, &-date {
        flex-basis: 100%;
    }
}
