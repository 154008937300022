// album dans la boucle de la liste ou widget
.galerie {
    &-album {
    margin-bottom: 2em;
        .inner {
            display:block;
        }
    }
    
    &-title {
    }
    
    &-subtitle {
    }
}