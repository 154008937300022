// Body
$body-bg: #222;

// Colors
$blue: #01709b;
$indigo: #6574cd;
$purple: #a64e9e;
$pink: #f66d9b;
$red: #e3342f;
$orange: #ee7203;
$yellow: #ffed4a;
$green: #89a83a;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$light: #f8f9fa;
$dark: #343a40;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$danger: $red;

// Les couleurs dominantes de la charte graphique peuvent être utilisées pour coloriser le texte avec TinyMCE, il suffit de recopier les héxadécimaux dans le fichier js\tinymce\tinymceConfig.js dans le tableau intitulé "textcolor_map"
$primary: #ee7203;
$secondary: #9d9d9d;
$colorDiscrete: $primary;

$bgcHeader: #222;
$bgcFooter: #222;

$fzH1: 40px;
$fzH2: 32px;
$fzH3: 28px;
$fzH4: 24px;
$fzH5: 20px;
$fzH6: 16px;

$colorTitle: white;
$colorText: white;
$colorLink: $primary;

$swiperThemeColor: $primary;

$fzText: 16px;

$ffTitle: "open_sans", Arial, sans-serif;
$ffText: "open_sans", Arial, sans-serif;
$ffItalic: "open_sans", Arial, sans-serif;
$ffBold: "open_sans", Arial, sans-serif;

$widthContainer: 1140px;

$mobile: '(max-width: 767px)';
$phone-portrait: '(max-width: 575px)';
$phone-landscape: '(min-width: 576px) and (max-width: 767px)';
$tablet-portrait: '(min-width: 768px) and (max-width: 991px)';
$tablet-landscape: '(min-width: 992px) and (max-width: 1199px)';
$large-desktop: '(min-width: 1200px) and (max-width: 1919px)';
$semi-desktop1: '(min-width: 1200px) and (max-width: 1439px)';
$semi-desktop2: '(min-width: 1440px) and (max-width: 1919px)';
$wide-desktop: '(min-width: 1920px)';
$non-retina: 'screen and (-webkit-max-device-pixel-ratio: 1)';
$retina: '(min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx)';
