#menu {
  padding: 0;
}

.primaryMenu {
  &-list {
    margin-top: 0;
    margin-left: 0;
      
    li#page-accueil{display: none}
  }

  &-link {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 15px;
  }
    
}

span.navbar-toggler-icon {
    width: 25px;
    height: 2px;
    background: $primary;
    display: block;
    margin: 5px auto;
}


/* --------- Sous-Menu / open Bootstrap 4 dropdown on hover --------- */
@media only screen and (min-width: 768px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }
}
.dropdown-menu{background-color: #222}

/*.primaryMenu-link{text-align: left;display:block}*/

.dropdown-item:hover, .dropdown-item:focus {
    color: #222;
    text-decoration: none;
    background-color: #ee7203;
}