
.widget_contact h3 {}

.vcard {
    .org {
        text-transform: uppercase;
    }

    .nickname {
        display: none;
    }

    .adr {
        .street-address, .postal-code {}

        .locality {
            text-transform: uppercase;
        }

        .country-name {
            display: none;
        }
    }

    .tel {}

    abbr {
        &.type {}

        border: 0;
    }

    .tel .value {}

    .telnumber {
        a {
            color: #fff;
            text-decoration: none;
        }

        .type, .value {}
    }

    .cellnumber {
        .type, .value {}
    }

    .faxnumber {
        display: none;

        .type, .value {}
    }

    .email_container {
        margin: 20px 0;
    }

    .email_name {
        display: none;
    }

    a.email {
        text-decoration: underline;
        display: none;

        &:hover {
            text-decoration: none;
        }
    }
}
