//partials
@import './front/base/appfront';
@import './front/vendors/swiper';
@import './front/home/diaporama';

// si widget actu activé
@import './front/widgets/actu-widget';
@import './front/widgets/webimmo-widget';

// si widget galerie activé
@import './front/widgets/galerie-widget';

// si widget recrutement activé
@import './front/widgets/recrutement-widget';

// si widget catalogue activé
@import './front/widgets/catalogue-widget';

.titre-home{
    padding: 0 100px;
    h1{font-size: 20px;font-weight: 400;text-transform: inherit;text-align: center;}
}




.phoenix{
    background: url(/img/phoenix.png) no-repeat center center;
    min-height: 150px;
    display: flex;
    align-items: center;
    h2, h3{
        color: $primary;
        font-size: 2.1rem;
        text-align: center;
        margin: auto;
        z-index: 100;   
    span{color:#fff}    
    }
}



/*
.phoenix h2{
    color: $primary;
    font-size: 2.1rem;
    text-align: center;
    margin: 40px auto 60px;
    z-index: 100;   
    span{color:#fff}
}

.phoenix:before{
    content: url(/img/phoenix.png);
    position:absolute; 
    left: 0;
    right: 0;
    top:-3px;
    z-index: 10;    
}
*/
.bad{margin: 50px auto}

.bad .gamme{margin-bottom: 10px}

.bad .bad-content{
    width: 100%;
    min-height: 280px;
    padding-top: 15px;
    display: block;
    text-align: center;
    position: relative;
    z-index: 100;
    box-shadow: 0 0 30px rgba(0,0,0,0.5);
        h3{font-size: 25px}
        span{
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        text-transform: uppercase;
        color:#222222;
        font-size: 17px;
        }
   
}



.bad-border{
    position: absolute;
    top: -5%;
    bottom: -5%;
    height: 110%;
    left: 20%;
    width: 60%;
    border: 2px solid;
    z-index: 10;
    transition: all 0.3s ease-in-out;
   
}

.gamme:hover > .bad-content{box-shadow: none;}
.gamme:hover > .bad-border{width: 101%;left: -1%;}

.bad-border.combustible{color:$orange}
.bad-border.ecologique{color:$green}
.bad-border.premium{color:$blue}
.bad-border.nettoyant{color:$purple}


a.combustible{
    background: $body-bg url(/img/combustible.jpg) no-repeat center center;
    background-size: cover; 
    h3{color:$orange}
}

a.ecologique{
    background: $body-bg url(/img/ecologique.jpg) no-repeat center center;
    background-size: cover;
     h3{color:$green}
}

a.premium{
    background: $body-bg url(/img/premium.jpg) no-repeat center center;
    background-size: cover;  
     h3{color:$blue}
}

a.nettoyant{
    background: $body-bg url(/img/nettoyant.jpg) no-repeat center center;
    background-size: cover;  
     h3{color:$purple}
}


.presentation{
    max-width: 1500px;
    margin:80px auto 100px;
    font-size: 20px;
    padding: 30px 0;
    box-shadow: 0 0 60px rgba(0,0,0,0.3);
    background: url(/img/presentation-bg.jpg) no-repeat center center;
    background-size: cover;

    &-imgfirst{
        margin-top: -100px;
        margin-right:-5px;
        img{    box-shadow: 0 0 60px rgba(0,0,0,0.3);}
    }    
    &-imgsecond{
        margin-bottom: -100px;
        margin-left: -5px;
        img{    box-shadow: 0 0 60px rgba(0,0,0,0.3);}
    }
    
    &-img{    align-items: center;}
}

.presentation{
    &-text{
        padding: 20px 0;
    }
    h3.charm{font-family: 'Charmonman';font-size: 30px;}
}


.carte{
    margin: 50px auto;
    background: #222 url('/img/bg-map.jpg') no-repeat left bottom;
    box-shadow: 0 0 60px rgba(0,0,0,0.3);
    &-text{
        padding: 80px 10% 80px 20%;
        text-align: right;
        background:  url('/img/bg-text.png') no-repeat right center;
        a.product-button{background: #222}
        
    }
    &-map{
    background: url(/img/map.jpg) no-repeat right center;
    background-size: contain;
    }

    
    /*.row{align-items: center;position: relative}*/
}



@media (max-width: 1500px) {
    .carte-text {  padding: 40px 15% 40px 5%; }
    
 }
@media (max-width: 991px) {
    .bad .gamme {  margin-bottom: 35px}
    .presentation-imgfirst, .presentation-imgsecond{margin: 0 auto;text-align: center;}
    .presentation-text { padding: 20px 50px;}
    .carte-text{background: none;padding: 40px 10%;}
    
}


@media (max-width: 767px) {
    .titre-home { padding: 50px 20px 0}
    .bad .bad-content {max-width: 90%; margin-left: 5%;}
    .presentation {  margin: 50px auto}
    .gamme:hover > .bad-border{width: 90%;left:5%;}
}
    













