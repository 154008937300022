// actu dans la boucle de la liste ou widget
.actualite {

    $parent: &;

    &-actu {

        &:hover {
            #{$parent}-img {
                opacity: .5;
            }
            /*
            #{$parent}-btn {
                @include hoverBouton;
            }*/
        }
    }
&-border{        
    padding: 30px;
    background: rgba(33,33,33,0.3);
    border: 1px solid #212121;}

    &-link {
        color: $colorText;
    }

    &-title {
      /*  text-align: left;*/
        font-weight: 700;
        font-size: 22px;
        text-transform: uppercase
    }

    &-link {
    }

    &-entry {
        font-size: rem($fzText * .9);
        display: block;
        padding-bottom: 15px;
    }

    &-date {

    }

    &-category {

    }

    &-content {
        display: block;
    }

    &-imgContainer {
        background-color: transparent;
        display: inline-block;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    &-img {
            max-height: 220px;
        transition: opacity .2s;
    }

    &-text {
       /* text-align: left;*/
        margin-top: 15px;
        margin-bottom: 15px;
        padding: 0 40px 30px;
    }

    &-btn {
        /*@include bouton($hover: false);*/
    }
}

.actu-block{min-height:260px;}

.widgetActualite-swiper{    padding-bottom: 50px;}