@import "node_modules/swiper/swiper";
@import "node_modules/swiper/modules/a11y/a11y";
@import "node_modules/swiper/modules/autoplay/autoplay";
// @import "node_modules/swiper/modules/effect-cards/effect-cards";
// @import "node_modules/swiper/modules/effect-coverflow/effect-coverflow";
// @import "node_modules/swiper/modules/effect-creative/effect-creative";
// @import "node_modules/swiper/modules/effect-cube/effect-cube";
// @import "node_modules/swiper/modules/effect-fade/effect-fade";
// @import "node_modules/swiper/modules/effect-flip/effect-flip";
// @import "node_modules/swiper/modules/free-mode/free-mode";
// @import "node_modules/swiper/modules/grid/grid";
// @import "node_modules/swiper/modules/hash-navigation/hash-navigation";
// @import "node_modules/swiper/modules/history/history";
// @import "node_modules/swiper/modules/keyboard/keyboard";
// @import "node_modules/swiper/modules/lazy/lazy";
// @import "node_modules/swiper/modules/manipulation/manipulation";
// @import "node_modules/swiper/modules/mousewheel/mousewheel";
@import "node_modules/swiper/modules/navigation/navigation";
@import "node_modules/swiper/modules/pagination/pagination";
// @import "node_modules/swiper/modules/parallax/parallax";
// @import "node_modules/swiper/modules/scrollbar/scrollbar";
// @import "node_modules/swiper/modules/thumbs/thumbs";
// @import "node_modules/swiper/modules/virtual/virtual";
// @import "node_modules/swiper/modules/zoom/zoom";

.swiper {
    width: 100%;
    height: 100%;

    &-slide {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-slide img {
        display: block;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }

    &-button-prev, &-button-next {
        @media (max-width: 767px) {
            display: none;
        }
    }
}
