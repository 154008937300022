header {
    position: relative;
  background: $bgcHeader;
  padding: 20px 0;
  box-shadow: 0 0 12px rgba(0,0,0,0.3);
}

a.tel-header{
    position: absolute;
    z-index: 1000;
    right: 10%;
    font-size: 1.2rem;
    font-weight: 700;
}


.topBar {
  display: flex;
  flex-wrap: wrap;
  line-height: 3em;
  justify-content: space-between;
}

#logo {
    text-align: center;
  img {
    max-width: 100%;
    height: auto;
  }
}

#menu{
    a{text-transform: uppercase;color:#fff}
}

@media (max-width:991px){
    a.tel-header{
    position: absolute;
    top: 10px;
    right: 15px;

    z-index: 1000;
   /* margin: auto;    text-align: center;*/
}
    
}