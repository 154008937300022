footer {
  background: $bgcFooter;
  padding: 20px 0;
}

#footer-menu {
  text-align: center;

  li {
    list-style: none;
    float: left;
    margin: 0 10px; 
  }

  a {
    color: #000;
    text-decoration: none;
  }
}

#footer-admin a {
  color: #000;
  text-decoration: none;
}

#footer-menu {
  a {
    font-size: 0.9em;

    &:hover {
      color: grey;
    }
  }

  li.active a {
    color: grey;
  }
}

#list-footer {
    text-align: center;
    margin: auto;
  list-style: none;

  li {
    display: inline-block;
    margin: 0 10px 0 0;
  }

  a {
      color:#fff;
    &#administration {
      /* si picto */
      /* display: block; text-indent: -9999px; background: url(../img/pictos.png) no-repeat 0 -31px transparent; width: 28px; height: 28px; */
    }

    &#mentions {
      /* si picto */
      /* display: block; text-indent: -9999px; background: url(../img/pictos.png) no-repeat 0 -31px transparent; width: 28px; height: 28px; */

      cursor: pointer;
      background-position: 0 0;
    }
  }
}

/* pictos en png */

#footer-realisation a {
  img {
    border: 0;
  }

  &:hover {
    background: none;
  }
}
