html {
    font-size: $html-font-size;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;

    @media (max-width: 991px) {
        font-size: rem($html-font-size * .8);
    }
}

body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    text-align: left;
    line-height: inherit;
    font-family: $ffText;
    font-size: rem($fzText); 
    --swiper-theme-color: #{$swiperThemeColor};
}

.hidden {
    display: none;
}

@media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: $widthContainer;
    }
}

h1, h2, h3, h4, h5, h6 {
    margin: rem(20px) 0;
    padding: 0;
    font-weight: 700;
    font-family: $ffTitle;
   /* color: $colorTitle;*/
}

$listStyles: ("h1": $fzH1, "h2": $fzH2, "h3": $fzH3, "h4": $fzH4, "h5": $fzH5, "h6": $fzH6);

@each $element, $size in $listStyles {
    #{$element} {
        font-size: rem($size);
    }
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="week"],
input[type="month"],
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
optgroup,
select,
textarea,
.form-control {
  border: 1px solid gainsboro;
  border-radius: 0px;
  padding: 0.5rem 1rem;
  background-color: #fff;
}

.form-control:focus {
    border-color: rgba($primary, .25);
    box-shadow: 0 0 0 0.2rem rgba($primary, .25);
}

ul, ol {
    margin: 10px;
    padding: 0;
    list-style-position: inside;
}

a {
    transition: all 0.2s linear;
    color: $colorLink;

    &:hover {
        text-decoration: none;
        color: $colorLink;
    }

    &:focus {
        outline: 0;
    }

    img {
        border: 0;
    }

    // &[href$=".pdf"] {
    //     padding: 10px 0 10px 30px;
    //     background: url(/img/pdf.png) no-repeat 0 50% transparent;
    // }
}

blockquote {
    margin: 30px 0;
    padding: 5px 15px;
    border-left: 4px solid #eee;
    font-style: italic;
}

em {
    font-style: italic;
}

strong {
    font-weight: 700;
}

.overflow {
    overflow: hidden;
}

.scrollToTop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1000000;
    display: none;

    &-icon {
        fill: $primary;
        width: 35px;
        height: 35px;
    }
}

.breadcrumb{background-color:transparent}

ol.breadcrumb {
    margin: 20px 0;
}

/* #CONTENT
================================================== */

#content {
    padding-bottom: 20px;
}

#page img, #content img {
    max-width: 100%;
    height: auto;
}

/* ------ mentions légales -----
#mentions-bloc { font-size: 0.8em; color: #000; width: 440px; position: fixed; left: 50%; margin-left: -220px; top: 50px; background: #fff; border: 10px solid #eee; padding: 10px; border-radius: 6px; display: none; box-shadow: 0 0 2px #000; z-index: 100000; }
@media (max-width: 767px) {
#mentions-bloc { width: 400px; font-size: 0.7em; margin-left: -200px}
}
#close_mentions { width: 20px; height: 20px; cursor: pointer; position: absolute; top: -5px; right: -5px; background: url(../img/close_mentions.png) no-repeat transparent; text-indent: -9999px; }

#mentions-bloc {
max-width: 550px;
border-radius: 4px;
color: #000;
font-size: 0.8em;
transform: translateY(-50px);
transition: all .33s;
}
*/

.fancybox-slide--current #hidden-content-b {
    transform: translateY(0);
}

/* ----- NOTIFICATIONS -----*/

.result_error {
    color: #fff;
    line-height: 40px;
    text-align: center;
    background: #e57c7c;
}

.result_success {
    color: #fff;
    line-height: 40px;
    text-align: center;
    background: #78ca71;
}

#flashMessage {
    background: #fff;
    color: #337ab7;
    line-height: 40px;
    text-align: center;
}

// Connexion
#tableau_login { overflow:hidden; }

#compte { margin: 20px auto}

#UserSiteLoginForm div, #UserAddUserForm div { margin: 0 0 10px; }

.error-message { color: #a94442}

// Contact
.accept {
    display: inline;
}

.error {
    .form-control {
        border-color: $danger;
    }
    .control-label {
        color: $danger;
    }
}

.mandatory {
    height: 0.1px;
    width: 0;
    border: 0 !important;
    padding: 0 !important;
}

.mentionRGPD {
    font-size: rem(14px);
}

#map {
    background: $gray-200;
    width: 100%;
    height: 500px;
    color: #000;
    .googlemaps-canvas {
        max-width: 100%;
    }
}

/* ----- DEMO ASSETS ----- */

.bs-component {
    .modal {
        position: relative;
        top: auto;
        right: auto;
        left: auto;
        bottom: auto;
        z-index: 1;
        display: block;
    }

    .modal-dialog {
        width: 90%;
    }
}

.modal-title {
    margin-top: 0;
}

/* ----- Message de consentement RGPD ----- */

.mentionRGPD {
    font-size: 0.8em;
}

// pagination
.pagination {
    justify-content: center;
}

.page {
    &-item {
        &.active {
            background-color: $primary;

            .page-link {
                background-color: $primary;
                border-color: $primary;
            }
        }
    }

    &-link {
        &, &:hover {
            color: $primary;
        }

        &:focus {
            box-shadow: none;
        }
    }
}

img {
    &[class^="align"] {
        max-width: 100%;
        height: auto;

        @media (max-width: 767px) {
            max-width: 100%;
            margin-bottom: 30px;
            margin-top: 30px;
            float: none;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &.alignright {
        max-width: calc(100% - 15px);
        float: right;
        margin-left: 30px;

        + figcaption {
            text-align: right;
            clear: both;
        }
    }

    &.alignleft {
        max-width: calc(100% - 15px);
        float: left;
        margin-right: 30px;
    }

    &.aligncenter {
        max-width: calc(100% - 30px);
        display: block;
        margin: 15px auto;

        + figcaption {
            text-align: center;
        }
    }
}

.googlemaps-canvas, .googlemaps-canvas > div {
    max-width: 100%;
}

.coralGrid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--gap, 30px);

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
    }

    &-item {
        grid-column-end: span var(--sizecolumnrounded);

        @media screen and (max-width: 767px) {
            grid-column-end: span 1;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }
}

// Surclasses les boutons Bootstrap
// $btns:
// "primary" $primary,
// "secondary" $secondary,
// "success" $green,
// "danger" $red,
// "warning" $yellow,
// "info" $cyan,
// "light" $light,
// "dark" $dark;
//
// @each $modifier, $colorBtn in $btns {
//     .btn-#{$modifier} {
//         @include bouton($hover: false, $color: $colorBtn);
//
//         &:not(:disabled):not(.disabled) {
//             &:hover, &:focus, &:active {
//                 @include hoverBouton($color: $colorBtn);
//             }
//         }
//     }
//
//     .btn-outline-#{$modifier} {
//         @include boutonOutline($hover: false, $color: $colorBtn);
//
//         &:not(:disabled):not(.disabled) {
//             &:hover, &:focus, &:active {
//                 @include hoverBoutonOutline($color: $colorBtn);
//             }
//         }
//     }
// }

.btn-primary,
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle, .btn-primary:focus, .btn-primary.focus{
    padding: 5px 30px;
    color: #fff; 
    background: $primary;
    font-size: 18px;
    text-transform: uppercase;
    border: 1px solid #fff;
    border-radius: 25px;
    background-color: $primary;
    border-color:$primary; 
}


.btn-primary:hover{
    background: #fff;
    color: #BD2426;}


.btn-default{
    padding: 5px 30px;
    color: #fff; 
    font-size: 18px;
    text-transform: uppercase;
    border: 1px solid #fff;
    border-radius: 25px;
     
}


.btn-default:hover{
    background: #fff;
    color: #BD2426;}



@media (max-width: 1100px) {
    .diaporama-blockTitle{
        top: 10% !important;
        left: 3% !important;
        width: 500px !important;
    }
}


@media (max-width: 991px) {
    nav.primaryMenu.navbar{ justify-content: flex-end;}
    a.primaryMenu-link {    text-align: center;}

}

@media (max-width: 767px) {
    .diaporama-blockTitle{position: relative !important;left: 0 !important}
    .diaporama-button{    margin: 10px auto 0 !important;}
    .swiper {  padding-bottom: 50px !important;}
    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal { bottom: 10px !important;}
    ul#slider_catalogue{margin: 0 auto}
    .carte-text a.product-button{font-size: 15px !important; padding: 5px 15px; white-space: nowrap;}
}
    
@media (max-width:580px){
    .btn-primary,
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle, .btn-primary:focus, .btn-primary.focus, .btn{font-size: 14px;}  
}
    
    
    
