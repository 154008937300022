@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-Regular-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-Regular-webfont.svg#open_sansregular') format('svg'),
         url('/fonts/OpenSans/OpenSans-Regular-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-Regular-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-BoldItalic-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-BoldItalic-webfont.svg#open_sansbold_italic') format('svg'),
         url('/fonts/OpenSans/OpenSans-BoldItalic-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-BoldItalic-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-Bold-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-Bold-webfont.svg#open_sansbold') format('svg'),
         url('/fonts/OpenSans/OpenSans-Bold-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-Bold-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-Semibold-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-Semibold-webfont.svg#open_sanssemibold') format('svg'),
         url('/fonts/OpenSans/OpenSans-Semibold-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-Semibold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.svg#open_sanssemibold_italic') format('svg'),
         url('/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-Italic-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-Italic-webfont.svg#open_sansitalic') format('svg'),
         url('/fonts/OpenSans/OpenSans-Italic-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-Italic-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-LightItalic-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-LightItalic-webfont.svg#open_sanslight_italic') format('svg'),
         url('/fonts/OpenSans/OpenSans-LightItalic-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-LightItalic-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-Light-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-Light-webfont.svg#open_sanslight') format('svg'),
         url('/fonts/OpenSans/OpenSans-Light-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-Light-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/OpenSans/open-sans-v34-latin-800.eot'); /* IE9 Compat Modes */
  src: url('/fonts/OpenSans/open-sans-v34-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/OpenSans/open-sans-v34-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/OpenSans/open-sans-v34-latin-800.woff') format('woff'), /* Modern Browsers */
       url('/fonts/OpenSans/open-sans-v34-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/OpenSans/open-sans-v34-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* charmonman-regular - latin */
@font-face {
  font-family: 'Charmonman';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/charmonman-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('/fonts/charmonman-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/charmonman-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/charmonman-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/charmonman-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/charmonman-v18-latin-regular.svg#Charmonman') format('svg'); /* Legacy iOS */
}