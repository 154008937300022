.diaporama {
    &-wrapper {

    }

    &-slide {
        
    }

    &-img {

    }

    &-blockTitle {
        position: absolute;
        top: 15%;
       /* background: #f00;*/
        left: 13%;
        width: 700px;
        text-align: center;
    }

    &-title {
        font-size:5vw;
        text-align: center;
        text-transform: uppercase;
        font-weight: 800;
        color:#fff;
        text-shadow: 0 0 10px rgba(0,0,0,1);
        /*
        &:after {
            display: block;
            width: 32vw;
            background: $primary;
            border-bottom: 1px #fff;
            height: 5px;
            margin: 15px auto 0;
            content: '';
        }*/
    }

    &-subTitle {
        font-size: 2.5vw;
        text-transform: uppercase;
        font-weight: 400;
        color:#fff;
        margin-top: 15px
    }
    
    &-button {
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        font-weight: 700;
        background: $primary;
        border-radius: 25px;
        color:#222;
        padding: 10px 20px;
        display: block;
        width: 250px;
        margin: 80px auto 0;
        box-shadow: 0 0 50px rgba(0,0,0,0.3);
        &:hover{box-shadow: none}
    }

    &-buttonNext, &-buttonPrev {

    }
    
    &-pagination {
        .swiper-pagination-bullet{
            background:#ee7203 ;
            width: 15px;
            height: 15px;
        }
    }
}

@media (max-width:580px){
    .diaporama-button{padding:10px ;width: 200px;font-size: 15px;}
}